export default {
  v: '5.6.10',
  fr: 25,
  ip: 0,
  op: 16,
  w: 146,
  h: 80,
  nm: 'wallet',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 48,
      h: 48,
      u: '/assets/lottie/',
      p: 'wallet.png',
      e: 0,
    },
    {
      id: 'image_1',
      w: 170,
      h: 170,
      u: '/assets/lottie/',
      p: 'gold.png',
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'wallet.webp',
      cl: 'webp',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [120, 50, 0], ix: 2},
        a: {a: 0, k: [24, 24, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 11,
              s: [100, 100, 100],
              e: [80, 80, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 13,
              s: [80, 80, 100],
              e: [110, 110, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 15,
              s: [110, 110, 100],
              e: [100, 100, 100],
            },
            {t: 17},
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 16,
      st: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: '09.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 4,
              s: [12, 45, 0],
              e: [90.618, 11.773, 0],
              to: [30.058, -26.531, 0],
              ti: [-19.666, -2.432, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 10,
              s: [90.618, 11.773, 0],
              e: [119, 46.5, 0],
              to: [22.886, 2.83, 0],
              ti: [4.153, -26.646, 0],
            },
            {t: 14},
          ],
          ix: 2,
        },
        a: {a: 0, k: [85, 85, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 4,
              s: [10, 10, 100],
              e: [15, 15, 100],
            },
            {t: 9},
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 4,
      op: 15,
      st: 4,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: '09.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              t: 3,
              s: [0],
              e: [222],
            },
            {t: 13},
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 3,
              s: [12, 33, 0],
              e: [87.081, 12.752, 0],
              to: [27.882, -15.521, 0],
              ti: [-18.381, -1.227, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 8,
              s: [87.081, 12.752, 0],
              e: [119, 46.5, 0],
              to: [26.673, 1.78, 0],
              ti: [4.879, -26.808, 0],
            },
            {t: 13},
          ],
          ix: 2,
        },
        a: {a: 0, k: [85, 85, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 3,
              s: [10, 10, 100],
              e: [15, 15, 100],
            },
            {t: 8},
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 3,
      op: 14,
      st: 3,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: '09.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              t: 2,
              s: [-233],
              e: [0],
            },
            {t: 8},
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 2,
              s: [17, 55, 0],
              e: [104.853, 12.516, 0],
              to: [37.018, -32.675, 0],
              ti: [-18.079, -3.9, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 9,
              s: [104.853, 12.516, 0],
              e: [119, 46.5, 0],
              to: [12.445, 2.685, 0],
              ti: [3.262, -20.929, 0],
            },
            {t: 12},
          ],
          ix: 2,
        },
        a: {a: 0, k: [85, 85, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 2,
              s: [10, 10, 100],
              e: [15, 15, 100],
            },
            {t: 7},
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 2,
      op: 13,
      st: 2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: '09.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              t: 2,
              s: [720],
              e: [0],
            },
            {t: 11},
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 1,
              s: [12, 45, 0],
              e: [94.618, 12.773, 0],
              to: [30.058, -26.531, 0],
              ti: [-19.815, 0.158, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 7,
              s: [94.618, 12.773, 0],
              e: [119, 46.5, 0],
              to: [21.386, -0.17, 0],
              ti: [4.153, -26.646, 0],
            },
            {t: 11},
          ],
          ix: 2,
        },
        a: {a: 0, k: [85, 85, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 1,
              s: [10, 10, 100],
              e: [15, 15, 100],
            },
            {t: 6},
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 1,
      op: 12,
      st: 1,
      bm: 0,
    },
  ],
  markers: [],
};
